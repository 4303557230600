<template>
  <v-app id="login">
    <v-main>
      <v-container class="fill-height background" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-icon left>mdi-login</v-icon>
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-form>
                  <v-text-field ref="username" autofocus :disabled="loading" label="Benutzername" :rules="userRule" v-model="username" prepend-icon="mdi-account" type="text" required @keydown.enter="login"></v-text-field>
                  <v-text-field ref="password" label="Kennwort" :disabled="loading" v-model="password" prepend-icon="mdi-lock" :rules="pwRule" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" :type="showPassword ? 'text' : 'password'" @keydown.enter="login"></v-text-field>
                </v-form>
                <div v-if="!!errorMessage" class="mt-6 error--text font-weight-bold text-center">
                  {{ errorMessage }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="login()" color="primary" block :disabled="buttonDisabled" :loading="loading">Anmelden</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar :value="showSnackbar" right> Sitzung abgelaufen </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },
  created() {
    this.showSnackbar = this.$store.state.username.length > 0;
    if (this.showSnackbar) this.username = this.$store.state.username;
  },
  data() {
    return {
      userRule: () => !!this.username || 'Dieses Feld sollte nicht leer sein!',
      pwRule: () => !!this.password || 'Dieses Feld sollte nicht leer sein!',
      showPassword: false,
      username: '',
      password: '',
      errorMessage: '',
      loading: false,
      showSnackbar: false,
    };
  },
  computed: {
    buttonDisabled() {
      return !this.username && !this.password;
    },
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        await this.$store.dispatch('login', {
          username: this.username,
          password: encodeURIComponent(this.password),
        });
        this.$router.push(this.$route.query.redirect || '/');
      } catch (error) {
        this.loading = false;
        this.password = '';
        this.errorMessage = error.Message ?? 'Ein Fehler ist aufgetreten!';
      }
    },
  },
};
</script>
